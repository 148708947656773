import React from 'react';
import Helmet from 'react-helmet';
import { LayoutProps, withLayout } from '../../components/Layout';
import { FixedMasthead } from '../../components/Masthead/FixedMasthead.component';
import { PageHeader } from '../../components/PageHeader/PageHeader.component';
import CallToContact from '../../components/CallToContact/CallToContact.component';
import {
    AndDivider,
    Blockquote,
    CheckList,
    Constrain,
    GuthenHeadingLevel3,
    GuthenHeadingLevel4,
    pullBackExtraWide,
    pullBackWide
} from '../../components/ui';
import { QuerySiteArgs } from '../../generated/graphql-types';
import styled from '../../styled-components';
import { media, mediaMax } from '../../utilities';

import checklistIllustration from './images/checklist.svg';
import treasureMap from './images/treasure-map.svg';
import boatLeavingDock from './images/boat-leaving-dock.svg';
import { BookACall } from '../../components/BookACall/BookACall';
import { SwellWrapper } from '../../components/SwellWrapper/SwellWrapper.component';
import socialShareImage from './images/social-image.png';
import andIllustration from '../../images/and-illustration.svg';
// Illustrations
import limpidMarketsIllustration from '../../../data/case-studies/limpid-markets-fintech/limpid-markets-illustration.svg';
import emittoIllustration from '../../../data/case-studies/emitto-app-covid-19-screening/emitto-app-illustration.svg';
import pubalIllustration from '../../../data/case-studies/pubal-saas-platform/pubal.svg';
import voteForPoliciesIllustration from '../../../data/case-studies/vote-for-policies/vote-for-policies.svg';
import { EvidencePullout } from '../../components/EvidencePullout/EvidencePullout';

const SLUG = 'entrepreneur-with-an-idea-for-innovative-web-or-mobile-app';

const RelatableHeading = styled.div`
    display: flex;
    flex-direction: column-reverse;

    .illustration {
        margin-bottom: 3rem;
    }

    h2 {
        margin-bottom: 1rem;
        font-size: ${({ theme }) => theme.fontNew.primary.size.level5.size};

        ${media.xl`
            font-size: ${({ theme }) => theme.fontNew.primary.size.level3.size};
            margin-bottom: 2rem;
            line-height: ${({ theme }) =>
                theme.fontNew.primary.size.level3.lineHeight};
        `}
    }

    p {
        font-size: ${({ theme }) => theme.fontNew.primary.size.level6.size};

        ${media.xl`
            font-size: ${({ theme }) => theme.fontNew.primary.size.level4.size};
            line-height: ${({ theme }) =>
                theme.fontNew.primary.size.level4.lineHeight};
        `}
    }

    ${media.m`
        display: flex;
        flex-direction: row;
        align-items: center;

        & > * {
            flex: 1;
        }

        &:last-child {
            flex-direction: row-reverse;
            .illustration {
                padding: 0 7rem 0 0;
            }
        }

        .illustration {
            flex: 1.5;
            padding: 0 0 0 7rem;
        }
    `}

    ${media.xl`
        .illustration {
            flex: 1;
            margin-right: ${pullBackExtraWide};
        }

        &:last-child {
            .illustration {
                margin-right: 0;
                margin-left: ${pullBackExtraWide};
            }
        }
    `}
`;

const Header = styled.section`
    margin-bottom: 6rem;

    ${media.m`
        margin-bottom: 12rem;
    `}

    .or {
        text-align: center;
        margin: 1.5rem 0 6rem 0;

        img {
            width: 85px;

            ${media.m`
                width: 120px;
            `}
        }
    }
`;

const HighlightHeader = styled.h2`
    font-size: ${({ theme }) => theme.fontNew.primary.size.level4.size};
    line-height: ${({ theme }) => theme.fontNew.primary.size.level4.lineHeight};
    margin-bottom: 8rem;

    mark {
        background-color: ${({ theme }) => theme.colors.electricBlue.color};
        color: ${({ theme }) => theme.colors.deepBlue.color};
        line-height: 1.2;
    }

    ${media.xl`
        font-size: ${({ theme }) => theme.fontNew.primary.size.level1.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level1.lineHeight};
    `}
`;

const TitleHighlight = styled.div`
    position: relative;
    white-space: nowrap;
    padding-right: 1rem;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: -100vw;
        background-color: ${({ theme }) => theme.colors.orange.color};
        z-index: -1;
    }
`;

const WhatWeDoSection = styled.section`
    margin-bottom: 5rem;

    ${media.m`
        margin-bottom: 10rem;
    `}

    .header {
        ${TitleHighlight} {
            display: inline-block;
            align-items: center;
            margin-bottom: 1rem;

            > * {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        ${media.m`
            display: flex;
            margin-bottom: 3rem;
            align-items: center;

            ${TitleHighlight} {
                flex-grow: 0;
                flex-shrink: 1;
                margin-bottom: 0;
                margin-right: 2rem;
            }

            h3 {
                margin-bottom: 0;
            }
        `}
    }

    ${mediaMax.m`
        p {
            margin: 0;
        }
    `}

    ${Blockquote} {
        ${media.l`
            transform: translateX(123px);
        `}
    }
`;

const ServiceListWrapper = styled.div`
    display: flex;
    align-items: flex-end;

    .checklist {
        display: none;
        width: 123px;

        ${media.m`
            display: block;
        `}

        ${media.l`
            margin-right: ${pullBackWide}
        `}
    }
`;

interface IEntrepreneurWithAnIdeaForInnovativeApp extends LayoutProps {
    data: {
        site: QuerySiteArgs;
    };
}

const EntrepreneurWithAnIdeaForInnovativeApp = ({
    data
}: IEntrepreneurWithAnIdeaForInnovativeApp) => {
    const socialMetaTitle =
        'Do you have an idea for an innovative web or mobile application?';
    const socialMetaDescription = 'We can help. Let\'s show you how.';

    return (
        <>
            <Helmet>
                <title>
                    How we help entrepreneurs build innovative web or mobile
                    applications
                </title>
                <meta
                    name="description"
                    content="How we help entrepreneurs build innovative web or mobile applications"
                />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@wemakewaves" />
                <meta name="twitter:title" content={socialMetaTitle} />
                <meta
                    name="twitter:description"
                    content={socialMetaDescription}
                />
                <meta
                    name="twitter:image"
                    content={`${
                        data.site.siteMetadata.siteUrl
                    }${socialShareImage}`}
                />

                <meta property="og:title" content={socialMetaTitle} />

                <meta
                    property="og:description"
                    content={socialMetaDescription}
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content={`${data.site.siteMetadata.siteUrl}/${SLUG}`}
                />
                <meta
                    property="og:image"
                    content={`${
                        data.site.siteMetadata.siteUrl
                    }${socialShareImage}`}
                />
            </Helmet>
            <FixedMasthead />

            <PageHeader
                highlightColor="electricBlue"
                title="You’re an entrepreneur with an idea for an innovative web or mobile app."
            />

            <Constrain type="narrow">
                <Header>
                    <RelatableHeading>
                        <div>
                            <h2>
                                You want to get to market quickly and have
                                confidence your budget is being spent wisely
                            </h2>
                            <p>
                                You’re excited to get going on your new venture
                                and want to get ahead of the competition by
                                launching quickly, testing it on your customers
                                and building confidence that your idea is worth
                                pursuing.
                            </p>
                        </div>
                        <div className="illustration">
                            <img
                                src={boatLeavingDock}
                                alt="Illustration of a newly built boat setting sail out of a dock"
                            />
                        </div>
                    </RelatableHeading>

                    <div className="or">
                        <img src={andIllustration} alt="Or" />
                    </div>

                    <RelatableHeading>
                        <div>
                            <h2>
                                You want to work with a team that will help you
                                accelerate your growth
                            </h2>
                            <p>
                                You’re ready for the growth stage. You need
                                strategic and technical help to identify new
                                user needs, ways in which your product can meet
                                them and a team capable of adapting your product
                                whilst measuring performance.
                            </p>
                        </div>
                        <div className="illustration left">
                            <img
                                src={treasureMap}
                                alt="An illustration of a treasure map guiding the newly launched boat"
                            />
                        </div>
                    </RelatableHeading>
                </Header>

                <HighlightHeader>
                    <mark>We can help, here’s how.</mark>
                </HighlightHeader>

                <WhatWeDoSection>
                    <div className="header">
                        <TitleHighlight>
                            <GuthenHeadingLevel3>
                                Lean strategy
                            </GuthenHeadingLevel3>
                        </TitleHighlight>

                        <h3>
                            Getting your Minimum Viable Product to market in two
                            months or less
                        </h3>
                    </div>

                    <p>
                        By identifying what you want to learn with your MVP and
                        the leanest route to delivering it, we’ll be able to
                        test your product to make sure it delivers the
                        anticipated value whilst minimising how much you spend.
                    </p>

                    <p>
                        We’ll map the user journeys we believe will deliver the
                        anticipated value, allocating a budget for your MVP to
                        serve as a constraint to ruthlessly prioritise what’s
                        needed. With the MVP built, we’ll then be able to test
                        if we’ve delivered the value, gather feedback and
                        determine what to do next.
                    </p>

                    <ServiceListWrapper>
                        <div>
                            <GuthenHeadingLevel4>
                                You'll hear us talk about
                            </GuthenHeadingLevel4>

                            <CheckList
                                items={[
                                    'Value hypotheses',
                                    'Growth hypotheses',
                                    'MVP',
                                    'Lean',
                                    'Story mapping',
                                    'Early adopters',
                                    'S.M.A.R.T experiments',
                                    'User research',
                                    'Risk mapping',
                                    'Research & Development'
                                ]}
                            />
                        </div>

                        <img
                            src={checklistIllustration}
                            alt="Sketch of a checklist"
                            className="checklist"
                        />
                    </ServiceListWrapper>

                    <AndDivider />

                    <EvidencePullout
                        illustration={limpidMarketsIllustration}
                        text="How we helped Limpid Markets beat the competition by getting to market quickly."
                        linkTo="/case-studies/limpid-markets-fintech/#lean-strategy"
                    />
                </WhatWeDoSection>

                <WhatWeDoSection>
                    <div className="header">
                        <TitleHighlight>
                            <GuthenHeadingLevel3>
                                Built for agility
                            </GuthenHeadingLevel3>
                        </TitleHighlight>

                        <h3>Software that’s easy to adapt as you learn</h3>
                    </div>

                    <p>
                        Your MVP, and every subsequent iteration, serves as an
                        opportunity to learn. You may uncover new user needs or
                        realise that your existing solution isn’t quite meeting
                        the needs it was intended to. Either way, what’s been
                        built will need changing to accommodate what you’ve
                        learnt. And that’s why it’s important to apply certain
                        technical practices for developing software to the
                        highest possible standards: so we can confidently and
                        rapidly adapt the software as you learn.
                    </p>

                    <ServiceListWrapper>
                        <div>
                            <GuthenHeadingLevel4>
                                You'll hear us talk about
                            </GuthenHeadingLevel4>
                            <CheckList
                                items={[
                                    'Continuous integration',
                                    'Test Driven Development',
                                    'Behavior Driven Development',
                                    'Agile',
                                    'Kanban',
                                    'MVP'
                                ]}
                            />
                        </div>

                        <img
                            src={checklistIllustration}
                            alt="Sketch of a checklist"
                            className="checklist"
                        />
                    </ServiceListWrapper>

                    <AndDivider />
                    
                    {/* one off offset for the illustration being too high */}
                    <div style={{marginTop: '-2rem'}}>
                        <EvidencePullout
                            illustration={emittoIllustration}
                            text="How we rapidly iterated a mobile app to respond to the emerging needs of the COVID-19 pandemic."
                            linkTo="/case-studies/emitto-app-covid-19-screening/#built-for-agility"
                        />
                    </div>

                </WhatWeDoSection>

                <WhatWeDoSection>
                    <div className="header">
                        <TitleHighlight>
                            <GuthenHeadingLevel3>
                                Learn and grow
                            </GuthenHeadingLevel3>
                        </TitleHighlight>

                        <h3>
                            Converting insights from feedback into an actionable
                            growth strategy
                        </h3>
                    </div>

                    <p>
                        An MVP is an important milestone but is only ever
                        intended as a stepping stone: an engine for learning.
                        Then it’s all about using what you’ve learnt, unlocking
                        new opportunities to accelerate your growth.
                    </p>

                    <p>
                        We’ll help interpret feedback to determine new user
                        needs, build the capabilities we think will meet them
                        and define the metrics to measure them against. We’ll
                        continue to iterate, exploring the growth opportunities
                        each step of the way.
                    </p>

                    <ServiceListWrapper>
                        <div>
                            <GuthenHeadingLevel4>
                                You'll hear us talk about
                            </GuthenHeadingLevel4>

                            <CheckList
                                items={[
                                    'User research',
                                    'Innovation accounting',
                                    'Continuous improvement',
                                    'Build measure learn',
                                    'Support',
                                    'Service Level Agreement'
                                ]}
                            />
                        </div>

                        <img
                            src={checklistIllustration}
                            alt="Sketch of a checklist"
                            className="checklist"
                        />
                    </ServiceListWrapper>

                    <AndDivider />

                        <EvidencePullout
                            illustration={pubalIllustration}
                            text="How we validated interest in an MVP, adapted and scaled it to a SaaS platform."
                            linkTo="/case-studies/pubal-saas-platform/#learn-and-grow"
                        />
    
                </WhatWeDoSection>

                <WhatWeDoSection>
                    <div className="header">
                        <TitleHighlight>
                            <GuthenHeadingLevel3>
                                Flying the nest
                            </GuthenHeadingLevel3>
                        </TitleHighlight>

                        <h3>Continuing the progress we’ve made together</h3>
                    </div>

                    <p>
                        As you grow they'll come a time when you’ll want to take
                        responsibility for the continued evolution of your
                        software. We’ll help you to hire your own team, sharing
                        knowledge with them by pair programming and co-writing
                        living documentation that provides information that’s
                        current, accurate and easy to understand. We won’t just
                        disappear though, we can continue to fill any gaps in
                        your team’s skill set as they get up to speed and
                        provide ongoing consultancy, acting as interim CTO as
                        needed.
                    </p>

                    <ServiceListWrapper>
                        <div>
                            <GuthenHeadingLevel4>
                                You'll hear us talk about
                            </GuthenHeadingLevel4>

                            <CheckList
                                items={[
                                    'Pair programming',
                                    'Interim CTO',
                                    'Behavior driven development',
                                    'Handover'
                                ]}
                            />
                        </div>

                        <img
                            src={checklistIllustration}
                            alt="Sketch of a checklist"
                            className="checklist"
                        />
                    </ServiceListWrapper>

                    <AndDivider />

                    <EvidencePullout
                        illustration={voteForPoliciesIllustration}
                        text="How a product we built for the 2015 general election is still in use today."
                        linkTo="/case-studies/vote-for-policies/#flying-the-nest"
                    />

                </WhatWeDoSection>
            </Constrain>

            <SwellWrapper
                title="Next steps."
                subtitle="Speak with one of our founders"
            >
                <BookACall
                    calendlyUrl={`https://calendly.com/we-make-waves/${SLUG}?background_color=003d4c&text_color=ffffff&primary_color=00eae9`}
                    pageUrl={SLUG}
                />
            </SwellWrapper>

            <CallToContact />
        </>
    );
};

export const query = graphql`
    query {
        site {
            siteMetadata {
                siteUrl
            }
        }
    }
`;

export default withLayout(EntrepreneurWithAnIdeaForInnovativeApp);
